<template>
  <page-layout>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">İletişim Bilgileri</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field v-model="name" label="Ad Soyad" outlined />
                <v-text-field
                  v-model="phone"
                  label="Telefon Numarası"
                  outlined
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field v-model="email" label="E-Posta Adresi" outlined />
                <v-text-field v-model="subject" label="Konu" outlined />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="message"
                  label="Lütfen bize iletmek istediğiniz içeriği yazın"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="25"
                />
              </v-col>
            </v-row>
            <v-row class="mt-5 pa-2">
              <v-btn
                block
                elevation="3"
                color="primary"
                large
                @click="sendMail"
              >
                Gönder
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import axios from '@axios';

import PageLayout from '@/components/PageLayout.vue';
export default {
  name: 'Contact',
  components: { PageLayout },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  }),
  methods: {
    sendMail() {
      axios
        .post('/contact', {
          name: this.name,
          phone: this.phone,
          email: this.email,
          subject: this.subject,
          message: this.message
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success('Mesaj Gönderildi.');
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    }
  }
};
</script>

<style lang="scss"></style>
